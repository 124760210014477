import { FC, useMemo, useState } from 'react';
import { Control } from 'react-hook-form';
import { Grid } from '@mui/material';
import { CashierPermissionsEnum } from 'enums/accessTemplates';

import { FormInput, FormSelect, Autocomplete } from 'components/common/Form';
import PhoneField from 'components/common/Form/PhoneField';
import useAuth from 'store/auth';
import { IShopListDTO, IUserCreateDTO, OptionType } from 'types';
import useMount from 'hooks/useMount';
import useCurrencies from 'store/currencies';
import { getBetShops } from 'api/requests/betShop';
import { getPos } from 'api/requests/shops';

interface Props {
  control: Control<Partial<IUserCreateDTO>>;
  permission: CashierPermissionsEnum;
}

const CreateUserFields: FC<Props> = ({ control, permission }) => {
  const [authState] = useAuth();
  const [betShops, setBetShops] = useState<IShopListDTO[]>([]);
  const [pos, setPos] = useState<IShopListDTO[]>([]);
  const [currencyState, currencyActions] = useCurrencies();

  useMount(() => {
    if (permission === CashierPermissionsEnum.BET_SHOP_ADMIN) {
      (async (): Promise<void> => {
        try {
          const res = await getBetShops({ page: '1', size: String(Number.MAX_SAFE_INTEGER) });
          setBetShops(res.data.data);
        } catch (error) {
          console.error(error);
        }
      })();
    }

    if (permission === CashierPermissionsEnum.POS_ADMIN) {
      (async (): Promise<void> => {
        try {
          const res = await getPos({ page: '1', size: String(Number.MAX_SAFE_INTEGER) });
          setPos(res.data.data);
        } catch (error) {
          console.error(error);
        }
      })();
    }

    if (permission !== CashierPermissionsEnum.PARTNER_ADMIN) {
      return;
    }

    (async (): Promise<void> => {
      try {
        await currencyActions.getPartner();
      } catch (error) {
        console.error(error);
      }
    })();
  });

  const currencies = useMemo(() => {
    if (permission === CashierPermissionsEnum.PARTNER_ADMIN) {
      return (currencyState.partnerCurrencies ?? []).map(
        (i) =>
          ({
            id: i.currencyCode,
            name: i.currencyCode,
          }) as OptionType,
      );
    }

    return (authState?.data?.cashier?.currencyCodes ?? []).map(
      (i) =>
        ({
          id: i,
          name: i,
        }) as OptionType,
    );
  }, [authState?.data?.cashier?.currencyCodes, currencyState.partnerCurrencies, permission]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <FormInput required label="Username" name="username" control={control} />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput required label="Password" name="password" type="password" control={control} />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput required label="Confirm Password" name="confirmPassword" type="password" control={control} />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput label="First Name" name="firstName" control={control} />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput label="Last Name" name="lastName" control={control} />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormInput required label="Email" name="email" control={control} />
      </Grid>
      <Grid item xs={12} md={6}>
        <PhoneField label="Phone" name="phoneNumber" control={control} />
      </Grid>
      {permission !== CashierPermissionsEnum.SUPER_ADMIN && (
        <Grid item xs={12} md={6}>
          <FormSelect
            required
            multiple
            label="Currencies"
            name="currencyCodes"
            control={control}
            options={currencies}
          />
        </Grid>
      )}

      {permission === CashierPermissionsEnum.BET_SHOP_ADMIN && (
        <Grid item xs={12} md={6}>
          <Autocomplete
            required
            options={betShops}
            name="shopId"
            control={control}
            displayProp="name"
            label="Bet Shops"
          />
        </Grid>
      )}
      {permission === CashierPermissionsEnum.POS_ADMIN && (
        <Grid item xs={12} md={6}>
          <Autocomplete required name="shopId" control={control} displayProp="name" label="POS" options={pos} />
        </Grid>
      )}
    </Grid>
  );
};

export default CreateUserFields;
