import type { FC } from 'react';
import { SyntheticEvent, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { FooterNavigationEnum } from 'enums/entity';
import { CashierPermissionsEnum } from 'enums/accessTemplates';

import useScreen from 'hooks/useScreen';
import ProfileIcon from 'components/common/icons/bulk/Profile';
import { translate } from 'utils/translate';
import NotificationIcon from 'components/common/icons/bulk/Notification';
import ScrollToTopFab from 'components/common/ScrollToTopFab';
import { AccessCreatePermissions, AccessTransferPermissions } from 'utils/accessTemplates';
import AddCircle from 'components/common/icons/AddCircle';
import MoneyCircle from 'components/common/icons/Money';

import CreateModal from '../MainModals/CreateModal';
import ChangePasswordModal from '../MainModals/ChangePasswordModal';
import TransferModal from '../MainModals/TransferModal';
import TransferPlayerModal from '../MainModals/TransferPlayerModal';

import ProfileDrawer from './ProfileDrawer';
import sx from './styles';

type Props = {
  existPermission: CashierPermissionsEnum;
};

const FooterNavigation: FC<Props> = ({ existPermission }) => {
  const [value, setValue] = useState<FooterNavigationEnum | null>(null);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const { isMobile } = useScreen();
  if (!isMobile) {
    return null;
  }

  const handleChange = (event: SyntheticEvent, newValue: FooterNavigationEnum): void => {
    setValue(newValue);
  };

  const handleClose = (): void => {
    setValue(null);
  };

  const handleOpenChangePassword = (): void => {
    setValue(null);
    setOpenChangePassword(true);
  };

  return (
    <Box sx={sx.root}>
      <BottomNavigation value={value} onChange={handleChange}>
        <BottomNavigationAction
          disabled
          label={translate('Notification')}
          value={FooterNavigationEnum.NOTIFICATION}
          icon={<NotificationIcon />}
        />
        {AccessCreatePermissions.some((r) => r === existPermission) && (
          <BottomNavigationAction
            label={translate('Create')}
            value={FooterNavigationEnum.CREATE}
            icon={<AddCircle />}
          />
        )}
        {AccessTransferPermissions.some((r) => r === existPermission) && (
          <BottomNavigationAction
            label={translate('Transfer')}
            value={FooterNavigationEnum.TRANSFER}
            icon={<MoneyCircle />}
          />
        )}
        <BottomNavigationAction
          label={translate('Profile')}
          value={FooterNavigationEnum.PROFILE}
          icon={<ProfileIcon />}
        />
      </BottomNavigation>
      {value === FooterNavigationEnum.CREATE && <CreateModal onClose={handleClose} permission={existPermission} />}
      {value === FooterNavigationEnum.TRANSFER && existPermission !== CashierPermissionsEnum.USER && (
        <TransferModal onClose={handleClose} />
      )}
      {value === FooterNavigationEnum.TRANSFER && existPermission === CashierPermissionsEnum.USER && (
        <TransferPlayerModal onClose={handleClose} />
      )}
      {value === FooterNavigationEnum.PROFILE && (
        <ProfileDrawer
          open={true}
          onClose={handleClose}
          onChangePassword={handleOpenChangePassword}
          permission={existPermission}
        />
      )}
      {openChangePassword && <ChangePasswordModal onClose={(): void => setOpenChangePassword(false)} />}
      <ScrollToTopFab />
    </Box>
  );
};

export default FooterNavigation;
