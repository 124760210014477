import TextField from '@mui/material/TextField';
import { MutableRefObject, SyntheticEvent, useCallback, useMemo, useRef } from 'react';
import {
  Box,
  Typography,
  SxProps,
  Theme,
  Autocomplete as BaseAutoComplete,
  AutocompleteInputChangeReason,
} from '@mui/material';
import noop from 'lodash/noop';

import { TranslationKey, translate } from 'utils/translate';
import useMount from 'hooks/useMount';

export interface IAutoCompleteProps<Option extends { id: number | string }, Type> {
  value?: Type;
  errorMessage?: string;
  options: Option[];
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  label?: TranslationKey;
  displayProp: keyof Option;
  inputLabel?: TranslationKey;
  placeholder?: TranslationKey;
  infiniteScroll?: boolean;
  onScroll?: () => void;
  onChange?: (value: Type) => void;
  externalOnChange?: (value: Type) => void;
  onInputChange?: (value: string) => void;
}

const sx = {
  input: {
    '& .MuiInputBase-input': { height: '19px' },
  },
  label: ({ disabled }: { disabled: boolean }): SxProps<Theme> => ({
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    color: disabled ? 'custom.grayscale.scale.50' : undefined,
  }),
};

const safePercent = 0.8;

const Autocomplete = <Option extends { id: number | string }, Type>({
  label,
  value,
  options,
  onChange,
  displayProp,
  errorMessage,
  externalOnChange,
  fullWidth = false,
  required = false,
  disabled = false,
  multiple = false,
  onInputChange,
  infiniteScroll = false,
  onScroll = noop,
  placeholder = 'Search...',
}: IAutoCompleteProps<Option, Type>): JSX.Element => {
  const valueOptions = useMemo(() => {
    if (!multiple) return;

    const values = Array.isArray(value) ? value : [value];

    return options.reduce<{ id: number | string }[]>((result, option) => {
      if (values.includes(String(option.id) as Type)) {
        result.push({
          id: String(option.id),
          [displayProp]: option[displayProp] as string,
        });
      }
      return result;
    }, []);
  }, [multiple, value, options, displayProp]);

  const scrollBleed = useRef<HTMLLIElement>(null) as MutableRefObject<HTMLLIElement | null>;
  const containerRef = useRef<HTMLDivElement>(null) as MutableRefObject<HTMLDivElement | null>;
  const disconnectRef = useRef<() => void>(noop);

  const safeIndex = Math.floor(options.length * safePercent);

  const handleOnChange = useCallback(
    (event: SyntheticEvent<Element, Event>, nextValue: Option | Option[] | null) => {
      const modifiedNextValue = !nextValue ? [] : Array.isArray(nextValue) ? nextValue : [nextValue];
      const valuesArr = modifiedNextValue.map((option) => String(option.id));

      onChange?.(!multiple && valuesArr[0] ? (valuesArr[0] as Type) : (valuesArr as Type));
      externalOnChange?.(!multiple && valuesArr[0] ? (valuesArr[0] as Type) : (valuesArr as Type));
    },
    [externalOnChange, multiple, onChange],
  );

  const handleInputChange = useCallback(
    (event: SyntheticEvent<Element, Event>, searchQuery: string, reason: AutocompleteInputChangeReason) => {
      if (reason !== 'reset') {
        onInputChange?.(searchQuery);
      }
    },
    [onInputChange],
  );

  const setupObserver = useCallback((): void => {
    const container = containerRef.current as HTMLElement;
    const bleed = scrollBleed.current as HTMLElement;

    if (!container || !bleed) return;

    disconnectRef.current();

    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting) {
          onScroll();
        }
      },
      { threshold: 1, root: container },
    );

    intersectionObserver.observe(bleed);

    disconnectRef.current = (): void => intersectionObserver.disconnect();
  }, [onScroll]);

  useMount(() => disconnectRef.current);

  const setBleedRef = useCallback(
    (node: HTMLLIElement) => {
      scrollBleed.current = node;
      setupObserver();
    },
    [setupObserver],
  );

  const setContainerRef = useCallback(
    (node: HTMLDivElement) => {
      containerRef.current = node;
      setupObserver();
    },
    [setupObserver],
  );

  const memoProps = useMemo(
    () => ({
      paper: {
        ref: setContainerRef,
      },
    }),
    [setContainerRef],
  );

  const isOptionEqualToValue = useCallback(
    (option: Option, selected: Option): boolean => String(option.id) === String(selected?.id),
    [],
  );

  return (
    <Box width={fullWidth ? '100%' : 'auto'}>
      <Typography sx={sx.label({ disabled })}>
        {label && translate(label)} {required && '*'}
      </Typography>
      <BaseAutoComplete<Option, boolean>
        options={options}
        disabled={disabled}
        multiple={multiple}
        onChange={handleOnChange}
        disableCloseOnSelect={multiple}
        onInputChange={handleInputChange}
        slotProps={memoProps}
        isOptionEqualToValue={isOptionEqualToValue}
        value={
          multiple
            ? (valueOptions as unknown as Option[])
            : (options.find(({ id }) => id === value) as unknown as Option)
        }
        getOptionLabel={(option): string => (option?.[displayProp] as string) || ''}
        renderOption={(props, option, { index }): JSX.Element => {
          const needsObserver = infiniteScroll && index === safeIndex;
          return (
            <li {...props} {...(needsObserver && { ref: setBleedRef })} key={option.id}>
              {option[displayProp] as string}
            </li>
          );
        }}
        renderInput={(params): JSX.Element => (
          <TextField {...params} placeholder={translate(placeholder)} sx={sx.input} />
        )}
      />
      {!!errorMessage && (
        <Typography variant="caption" color="error">
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};
export default Autocomplete;
