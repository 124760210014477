import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { Stack, Box, Typography, SelectChangeEvent } from '@mui/material';
import { TransferTypeEnum } from 'enums/entity';

import useToaster from 'hooks/useToaster';
import Modal from 'components/common/Modal';
import TransferIcon from 'components/common/icons/bulk/Transfer';
import Form, { FormNumberInput, FormSelect, FormInput } from 'components/common/Form';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { transferPlayerMoneySchema } from 'configs/validationSchemes';
import { translate, TranslationKey } from 'utils/translate';
import { CUSTOM_TRANSFER_TYPE_OPTIONS } from 'configs';
import { ITransferPlayerMoney } from 'types/transfer';
import {
  getPlayerTransferDepositInfo,
  createTransferPlayerDeposit,
  getPlayerTransferWithdrawInfo,
  createTransferPlayerWithdraw,
} from 'api/requests/transfer';
import { ApiErrorType } from 'types';
import useScreen from 'hooks/useScreen';
import useDidMount from 'hooks/useDidMount';
import { useDebounce } from 'hooks/useDebounce';

interface Props {
  onClose: () => void;
}

const TransferModal: FC<Props> = ({ onClose }) => {
  const { isMobile } = useScreen();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState('');
  const [selectedUsername, setSelectedUsername] = useState('');
  const [selectedAmount, setSelectedAmount] = useState('');
  const [selectedTicketId, setSelectedTicketId] = useState<number | null>();
  const notify = useToaster();

  const resolver = useYupValidationResolver<Partial<ITransferPlayerMoney>>(
    transferPlayerMoneySchema as ObjectSchema<Partial<ITransferPlayerMoney>>,
  );
  const { control, watch, reset, handleSubmit } = useForm<Partial<ITransferPlayerMoney>>({
    resolver,
    defaultValues: {
      type: CUSTOM_TRANSFER_TYPE_OPTIONS?.[0]?.id,
      playerId: undefined,
      amount: undefined,
      code: undefined,
    },
  });

  const type = watch('type');
  const playerId = watch('playerId');
  const code = watch('code');

  const handleTypeChange = (data: SelectChangeEvent<unknown>): void => {
    setSelectedUsername('');
    setSelectedCurrencyCode('');
    setSelectedAmount('');
    setSelectedTicketId(null);
    reset({
      type: data.target.value as TransferTypeEnum,
      playerId: undefined,
      amount: undefined,
      code: undefined,
    });
  };

  const handleCreateSubmit = async (data: Partial<ITransferPlayerMoney>): Promise<void> => {
    if (data.type === TransferTypeEnum.DEPOSIT && (!selectedCurrencyCode || !selectedUsername)) {
      return;
    }
    if (data.type === TransferTypeEnum.WITHDRAW && (!selectedCurrencyCode || !selectedAmount || !selectedTicketId)) {
      return;
    }

    setIsLoading(true);

    try {
      data.type === TransferTypeEnum.DEPOSIT
        ? await createTransferPlayerDeposit({
            amount: data.amount,
            playerId: playerId,
          })
        : await createTransferPlayerWithdraw({
            ticketId: Number(selectedTicketId),
          });
      setIsLoading(false);
      notify({
        message: 'Success',
        type: 'success',
      });
      onClose();
    } catch (error) {
      setIsLoading(false);
      notify({
        message: ((error as unknown as ApiErrorType)?.text as TranslationKey) || translate('Something went wrong'),
        type: 'error',
      });
    }
  };

  const getTransferDepositInfo = async (): Promise<void> => {
    if (type === TransferTypeEnum.WITHDRAW) {
      setSelectedUsername('');
      return;
    }

    if (!playerId) {
      setSelectedUsername('');
      setSelectedCurrencyCode('');
      return;
    }

    setIsLoading(true);
    try {
      const response = await getPlayerTransferDepositInfo(Number(playerId));
      setSelectedCurrencyCode(response.data?.currencyCode || '');
      setSelectedUsername(response.data?.username || '');
    } catch (error) {
      console.error(error);
      notify({
        message: ((error as unknown as ApiErrorType)?.text as TranslationKey) || translate('Something went wrong'),
        type: 'error',
      });
      setSelectedUsername('');
      setSelectedCurrencyCode('');
    }

    setIsLoading(false);
  };

  const getTransferWithdrawInfo = async (): Promise<void> => {
    if (type === TransferTypeEnum.DEPOSIT) {
      setSelectedAmount('');
      setSelectedTicketId(null);
      return;
    }

    if (!playerId || !code) {
      setSelectedAmount('');
      setSelectedCurrencyCode('');
      setSelectedTicketId(null);
      return;
    }

    setIsLoading(true);

    try {
      const response = await getPlayerTransferWithdrawInfo(Number(playerId), code);
      setSelectedCurrencyCode(response.data?.currencyCode || '');
      setSelectedAmount(response.data?.amount || '');
      setSelectedTicketId(response.data?.ticketId);
    } catch (error) {
      console.error(error);
      notify({
        message: ((error as unknown as ApiErrorType)?.text as TranslationKey) || translate('Something went wrong'),
        type: 'error',
      });
      setSelectedUsername('');
      setSelectedCurrencyCode('');
      setSelectedTicketId(null);
    }

    setIsLoading(false);
  };

  const debouncedHandleGetDepositInfo = useDebounce(getTransferDepositInfo, 1000);
  const debouncedHandleGetWithdrawInfo = useDebounce(getTransferWithdrawInfo, 1000);

  const onSave = async (): Promise<void> => {
    handleSubmit(handleCreateSubmit)();
  };

  useDidMount(() => {
    debouncedHandleGetDepositInfo();
  }, [playerId]);

  useDidMount(() => {
    debouncedHandleGetWithdrawInfo();
  }, [playerId, code]);

  return (
    <Modal
      maxWidth={544}
      fullScreen={isMobile}
      saveText="Apply"
      icon={<TransferIcon />}
      title="Money Transfer"
      isLoading={isLoading}
      onClose={onClose}
      onSave={handleSubmit(onSave)}
    >
      <Box>
        <Stack spacing={2}>
          <Form onSubmit={handleSubmit(handleCreateSubmit)}>
            <Stack spacing={2}>
              <FormSelect
                required
                control={control}
                disabled={isLoading}
                name="type"
                label="Type"
                options={CUSTOM_TRANSFER_TYPE_OPTIONS}
                onChange={handleTypeChange}
              />
              <FormInput required control={control} name="playerId" label="Player ID" />
              {type === TransferTypeEnum.DEPOSIT && (
                <FormNumberInput required control={control} name="amount" label="Amount" />
              )}
              {type === TransferTypeEnum.DEPOSIT && selectedUsername && (
                <Typography>
                  {translate('Username')}: {selectedUsername || ''}
                </Typography>
              )}
              {type === TransferTypeEnum.DEPOSIT && selectedCurrencyCode && (
                <Typography>
                  {translate('Currency Code')} {selectedCurrencyCode}
                </Typography>
              )}
              {type === TransferTypeEnum.WITHDRAW && <FormInput required control={control} name="code" label="Code" />}
              {type === TransferTypeEnum.WITHDRAW && selectedAmount && (
                <Typography>
                  {translate('Amount')}: {selectedAmount || ''}
                </Typography>
              )}
              {type === TransferTypeEnum.WITHDRAW && selectedCurrencyCode && (
                <Typography>
                  {translate('Currency Code')} {selectedCurrencyCode}
                </Typography>
              )}
            </Stack>
          </Form>
        </Stack>
      </Box>
    </Modal>
  );
};

export default TransferModal;
