import { Controller, FieldValues, FieldPath, Control, useController } from 'react-hook-form';
import { omit } from 'lodash';

import Autocomplete, { IAutoCompleteProps } from 'components/common/AutoComplete';

interface FormAutocompleteProps<
  Option extends { id: number | string },
  Type,
  TFieldValues extends FieldValues = FieldValues,
> extends Omit<IAutoCompleteProps<Option, Type>, 'value'> {
  name: FieldPath<TFieldValues>;
  control?: Control<TFieldValues>;
}

const FormAutocomplete = <
  Option extends { id: number | string },
  Type,
  TFieldValues extends FieldValues = FieldValues,
>({
  control,
  name,
  ...rest
}: FormAutocompleteProps<Option, Type, TFieldValues>): JSX.Element | null => {
  const {
    field: restControl,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  if (!name || !control) {
    return null;
  }

  return (
    <Controller
      {...omit(restControl, 'ref')}
      name={name}
      render={({ field }): JSX.Element => (
        <Autocomplete
          {...rest}
          value={field.value}
          errorMessage={error?.message}
          externalOnChange={rest.onChange}
          onChange={field.onChange}
        />
      )}
      control={control}
    />
  );
};

export default FormAutocomplete;
