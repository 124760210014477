import { CashierPermissionsEnum } from 'enums/accessTemplates';

import { OptionType } from 'types';
import { PERMISSIONS_OPTIONS } from 'configs';

export const generatePermissionList = (existPermission: string[]): OptionType[] => {
  if (existPermission.includes(CashierPermissionsEnum.SUPER_ADMIN)) {
    return PERMISSIONS_OPTIONS.filter((i) => i.id !== CashierPermissionsEnum.SUPER_ADMIN);
  } else {
    return PERMISSIONS_OPTIONS.filter((i) => existPermission.includes(i.id));
  }
};

export const generateDefaultValue = (
  existPermission: string[],
  selectedPermission: (boolean | CashierPermissionsEnum | undefined)[],
): boolean[] => {
  const options = generatePermissionList(existPermission);
  const permissions: boolean[] = [];
  options.forEach((i) => {
    permissions.push(selectedPermission.includes(i.id as CashierPermissionsEnum));
  });

  return permissions;
};

export const AccessSuperAdminPermissions = [CashierPermissionsEnum.SUPER_ADMIN];

export const AccessChangePartnerPermissions = [CashierPermissionsEnum.SUPER_ADMIN];

export const AccessTransferPermissions = [
  CashierPermissionsEnum.PARTNER_ADMIN,
  CashierPermissionsEnum.BET_SHOP_ADMIN,
  CashierPermissionsEnum.USER,
];

export const AccessCreatePermissions = [
  CashierPermissionsEnum.SUPER_ADMIN,
  CashierPermissionsEnum.PARTNER_ADMIN,
  CashierPermissionsEnum.BET_SHOP_ADMIN,
  CashierPermissionsEnum.POS_ADMIN,
];

export const AccessCreateUserPermissions = [
  CashierPermissionsEnum.SUPER_ADMIN,
  CashierPermissionsEnum.PARTNER_ADMIN,
  CashierPermissionsEnum.BET_SHOP_ADMIN,
  CashierPermissionsEnum.POS_ADMIN,
];

export const AccessCreateShopPermissions = [
  CashierPermissionsEnum.PARTNER_ADMIN,
  CashierPermissionsEnum.BET_SHOP_ADMIN,
  CashierPermissionsEnum.POS_ADMIN,
];

export const AccessCashiersInfoPermissions = [
  CashierPermissionsEnum.SUPER_ADMIN,
  CashierPermissionsEnum.PARTNER_ADMIN,
  CashierPermissionsEnum.BET_SHOP_ADMIN,
  CashierPermissionsEnum.POS_ADMIN,
  CashierPermissionsEnum.USER,
];
