import { FC, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ObjectSchema } from 'yup';
import { Stack, Box } from '@mui/material';

import useToaster from 'hooks/useToaster';
import Modal from 'components/common/Modal';
import Form, { FormNumberInput, FormSelect } from 'components/common/Form';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { createVoucherSchema } from 'configs/validationSchemes';
import { translate, TranslationKey } from 'utils/translate';
import { OptionType } from 'types/utils';
import { ApiErrorType } from 'types';
import useScreen from 'hooks/useScreen';
import useAuth from 'store/auth';
import { createVoucher } from 'api/requests/voucher';
import VoucherIcon from 'components/common/icons/bulk/Voucher';
import Copy from 'components/common/Copy';

interface Props {
  onClose: () => void;
}

const TransferModal: FC<Props> = ({ onClose }) => {
  const { isMobile } = useScreen();
  const [isLoading, setIsLoading] = useState(false);
  const [authState] = useAuth();
  const notify = useToaster();

  const [code, setCode] = useState('');

  type IVoucherForm = { amount: string; currencyCode: string };

  const resolver = useYupValidationResolver<Partial<IVoucherForm>>(
    createVoucherSchema as ObjectSchema<Partial<IVoucherForm>>,
  );
  const { control, handleSubmit } = useForm<IVoucherForm>({
    resolver,
  });

  const handleCreateSubmit = async (data: IVoucherForm): Promise<void> => {
    setIsLoading(true);

    try {
      const response = await createVoucher(data);
      setIsLoading(false);
      notify({
        message: 'Success',
        type: 'success',
      });

      setCode(response.data.confirmationCode);
    } catch (error) {
      setIsLoading(false);
      notify({
        message: ((error as unknown as ApiErrorType)?.text as TranslationKey) || translate('Something went wrong'),
        type: 'error',
      });
    }
  };

  const currencyOptions = useMemo(
    () => authState.data?.cashier?.currencyCodes.map((currencyCode) => ({ id: currencyCode, name: currencyCode })),
    [authState.data?.cashier?.currencyCodes],
  );

  const onSave = async (): Promise<void> => {
    handleSubmit(handleCreateSubmit)();
  };

  return (
    <Modal
      maxWidth={544}
      onClose={onClose}
      isLoading={isLoading}
      fullScreen={isMobile}
      icon={<VoucherIcon />}
      title="Create Voucher"
      cancelText="Close"
      onSave={code ? undefined : handleSubmit(onSave)}
      saveText="Generate"
    >
      <Box>
        <Stack spacing={2}>
          <Form onSubmit={handleSubmit(handleCreateSubmit)}>
            <Stack spacing={2}>
              <FormSelect
                required
                control={control}
                name="currencyCode"
                label="Currency"
                options={currencyOptions as unknown as OptionType[]}
              />
              <FormNumberInput required control={control} name="amount" label="Amount" />
            </Stack>
          </Form>
          {code && (
            <Box>
              {`${translate('Confirmation Code')}:  ${code}`} <Copy value={code} />
            </Box>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default TransferModal;
